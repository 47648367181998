import React from 'react';
import AlertIcon from '../assets/alert-icon.svg';

const KitCompletionStepGraphic = (props) => {
    let sectionImg = props.sectionImg.split('/').pop();
    return(
    <section className="kit-completion-step-graphic">
        <div className="right-col-content-wrap">
            <div className="inner-wrap">
            {(props.alert === 'with-alert') &&
                <AlertIcon 
                    className="alert-icon"
                />
            }
                <div className="section-columns">
                    <div className="left-col" dangerouslySetInnerHTML={{__html: props.sectionText }} />
                    <div className={`right-col ${props.sectionImgSize}`}>
                        <img src={`/${sectionImg }`} alt={props.imgAltText} />
                    </div>
                </div>
            </div>
        </div>
    </section>
)
}

export default KitCompletionStepGraphic;