import React from 'react';
import {Link} from 'gatsby';

const NavigationalButtonSet = (props) => {
    return(
        <section className="navigational-button-set">
            <div className="right-col-content-wrap">
                <div className="section-columns">
                {props.leftBtn &&
                    <Link 
                        className="left-button site-button" 
                        to={props.leftBtn.url}
                        dangerouslySetInnerHTML={{ __html:props.leftBtn.title}} />
                }

                {props.rightBtn &&
                    <Link 
                        className="right-button site-button" 
                        to={props.rightBtn.url}
                        dangerouslySetInnerHTML={{ __html:props.rightBtn.title}} />
                }
                </div>
            </div>
        </section>
    )
}

export default NavigationalButtonSet;