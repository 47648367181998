import React from 'react';

const ThirdColumnImagesAndText = (props) => {

    return(
        <div className="third-column-images-and-text">
            <div className="third-columns-wrap">
                {
                    props.column.map((column, i) => {
                        let colImg = column.column_image.split('/').pop();
                        let colImgAlt = column.column_image_alt_text
                        let colText = column.column_text;
                        return(
                            <div className="third-column" key={`tc-col-${i}`}>
                                <div className="column-image">
                                    <img src={`/${colImg}`} alt={colImgAlt} />
                                </div>
                                {colText && 
                                    <div className="column-text">
                                    <h3>{colText}</h3>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ThirdColumnImagesAndText;