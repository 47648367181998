import React from 'react';
import SideMenu from './SideMenu';
import SideMenuMRP from './SideMenuMRP';
import SideMenuWRP from './SideMenuWRP';
import SideMenuNutro from './SideMenuNutro';

class MobileSideMenu extends React.Component{
constructor(props){  
    super(props);  
    this.state = {  
       drawerOpen: false,
       parentName: 'click me'   
    }  
    this.clickMenuDrawer = this.clickMenuDrawer.bind(this);  
} 


clickMenuDrawer(){  
    this.setState({
        drawerOpen: !this.state.drawerOpen
    });
 }  

  
componentDidMount(){
    let activeSubMenuItem = document.querySelector('.side-menu .active');
    
    if(activeSubMenuItem){
        let parentNode = activeSubMenuItem.closest('.primary > li');
        parentNode.className += ' active-tree-parent-node';
    } else {
        let menuFirstChild = document.querySelector('.side-menu .primary').firstChild;
        menuFirstChild.className += ' active-tree-parent-node unnested';
    }

    if(this.props.parent){
 
      this.setState({
        parentName: this.props.parent.toUpperCase()
      });
    
    } else {
      this.setState({
        parentName: this.props.slug.toUpperCase()
      });
    }
    

    
}

componentDidUpdate(){

    const getParents = function (elem, selector) {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
              var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
              while (--i >= 0 && matches.item(i) !== this) {}
              return i > -1;
            };
        }
      
        // Set up a parent array
        var parents = [];
      
        // Push each parent element to the array
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
          if (selector) {
            if (elem.matches(selector)) {
              parents.push(elem);
            }
            continue;
          }
          parents.push(elem);
        }
      
        // Return our parent array
        return parents;
      
    };

    const sideLinks = document.querySelectorAll(".mobile-version .side-menu-link");
        sideLinks.forEach(function(item) {
        if(item.classList.contains("active")){
            getParents(item, 'ul').forEach(function(parentUl) {
            parentUl.className += " show-me";
            });
        }
    });
}

  
  
render() {    
    return(
      <section className={`mobile-side-menu ${this.state.drawerOpen ? 'drawer-open' : null}`}>
        <div className="site-container">
            {
                !this.state.drawerOpen &&
                <button className="mobile-toggle-secondary-menu" onClick={this.clickMenuDrawer}>{this.state.parentName}</button>
            }

          { //conditional side menu rendered depending on page slug or parent slug
            ((this.props.slug === 'usrp-arp' || this.props.parent === 'usrp-arp') && this.state.drawerOpen) ?
            <SideMenu extraClass="mobile-version" withLogo={false} /> : 
              ((this.props.slug === 'usrp-mrp' || this.props.parent === 'usrp-mrp') && this.state.drawerOpen) ? 
                <SideMenuMRP extraClass="mobile-version" withLogo={false} /> :
                ((this.props.slug === 'usrp-wrp' || this.props.parent === 'usrp-wrp') && this.state.drawerOpen) ? 
                <SideMenuWRP extraClass="mobile-version" withLogo={false} /> :
                  ((this.props.slug === 'nutro' || this.props.parent === 'nutro') && this.state.drawerOpen) ? 
                  <SideMenuNutro extraClass="mobile-version" withLogo={false} /> :
                  null
          }


        </div>
      </section>
    )
  }
}

export default MobileSideMenu;
