import React from 'react';

const ChecklistGroup = (props) => {
    return(
        <section className="checklist-group">
            <div className="right-col-content-wrap">
                <div className="group-content" dangerouslySetInnerHTML={{__html: props.groupContent}} />
            </div>
        </section>
    )
}

export default ChecklistGroup;