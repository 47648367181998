import React from "react"
import SkipTo from './SkipTo';
import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter'
import SideMenu from './SideMenu';
import SideMenuMRP from './SideMenuMRP';
import SideMenuWRP from './SideMenuWRP';
import SideMenuNutro from './SideMenuNutro';
import MobileSideMenu from './MobileSideMenu';
import ScrollToTop from './ScrollToTop';
import "../scss/styles.scss"

//passing props with {children} requires mapping children and cloning the element to include the props with it
const LayoutWithSide = ({ children, ...props }) => {
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      rcstyles: props.rcStyle
    }),
);

  return(
    <div className="main-layout">
    <SkipTo />
    <div className="layout-interior with-side-menu">
        <div className="left-col">
       
        { //conditional side menu rendered depending on page slug or parent slug
          (props.slug === 'usrp-arp' || props.parent === 'usrp-arp') ?
          <SideMenu withLogo={true} extraClass="desktop-version" /> : 
            (props.slug === 'usrp-mrp' || props.parent === 'usrp-mrp') ? 
              <SideMenuMRP withLogo={true} extraClass="desktop-version" /> :
              (props.slug === 'usrp-wrp' || props.parent === 'usrp-wrp') ? 
              <SideMenuWRP withLogo={true} extraClass="desktop-version" /> :
                (props.slug === 'nutro' || props.parent === 'nutro') ? 
                <SideMenuNutro withLogo={true} extraClass="desktop-version" /> :
                  null
        }

        </div>
        <div className={`right-col${(props.rcStyle === 'all-grey') ? ' all-grey-right-col' : ''}`}>
        <SiteHeader noLogo={true} rcStyle={props.rcStyle} slug={props.slug} parent={props.parent} />
        <MobileSideMenu  slug={props.slug} parent={props.parent} />
          <div id="main">
            {childrenWithProps}
          </div>
        </div>
    </div>
    <SiteFooter />
    <ScrollToTop />
  </div>
  )

}
export default LayoutWithSide
