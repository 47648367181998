import React from 'react';
import VideoModalTrigger from './VideoModalTrigger';
import {Link} from 'gatsby';
import AlertIcon from '../assets/alert-icon.svg';

const VideoModalModule = (props) => {
    return(
        <section className={`video-modal-section ${(props.alert === 'display-icon') ? 'with-alert' : ''}`} >
            <div className="right-col-content-wrap">
                {(props.alert === 'display-icon') &&
                    <AlertIcon
                        className="alert-icon"
                    />
                }
                {props.videoTitle &&
                <h2 className={`optional-title ${(props.videoTitleStyle === 'light-black') ? ' light-black' : ''}`}>{props.videoTitle}</h2>
                }
                <VideoModalTrigger 
                    videoThumb={props.videoThumb} 
                    videoCode={props.videoCode}
                />
                {props.optionalLinks &&
                    <div className="optional-links">
                        {props.optionalLinks.map(optionalLink => (
                            <Link 
                                to={optionalLink.video_section_optional_link.url}
                                className={`arrow-link ${optionalLink.video_section_link_arrow_direction}`}
                            >
                            {optionalLink.video_section_optional_link.title}
                            </Link>
                                
                        ))}
                    </div>

                }
            </div>
        </section>
    )
}

export default VideoModalModule;