import React from 'react';

const SingleImage = (props) => {
    let imgPath = props.imgUrl.split('/').pop();

    return(
        <section className="single-image-section">
            <div className="right-col-content-wrap">
                {props.optionalHeading &&
                <h2 className="optional-heading">{props.optionalHeading}</h2>
                }
                <div className={`img-wrap ${props.imgPadding}`}>
                    <img src={`/${imgPath}`} alt={props.imgAlt} />
                </div>
            </div>
        </section>
    )
}

export default SingleImage;