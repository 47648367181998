import React from 'react';
import {Link} from 'gatsby';
import ThirdColumImagesAndText from './ThirdColumImagesAndText';
import AlertIcon from '../assets/alert-icon.svg';

const BasicText = (props) => {
   
        let iconUrl = props.icon;
        if(iconUrl){
            iconUrl = iconUrl.split('/').pop();
            iconUrl = `/`+iconUrl;
        }
       
   
    return(
        <section className={`basic-text${(props.rcStyle === 'all-grey') ? ' all-grey-parent-column' : ''}`} id={props.sectionID}>
            <div className={props.wrapperClass}>
                
                <div className={`top-wrap ${(props.alert === 'display-icon') ? ' with-alert' : '' }`}>
                    {(props.alert === 'display-icon') &&
                        <AlertIcon
                            className="alert-icon"
                        />
                    }
                    <div className="text-wrap" dangerouslySetInnerHTML={{__html: props.sectionText }} />
                    {iconUrl &&
                        <img className="section-icon" src={`${iconUrl }`} alt={props.iconalt} />
                    }
                </div>
                {props.tcColumns &&
                    <ThirdColumImagesAndText 
                        column={props.tcColumns}
                    />
                }

                {props.linksArray &&
                <div className={`links-columns${(props.displayLinks === 'vertically') ? ' vertical' : ''}`}>
                    {props.linksArray.map((column, i) => {
                        let linkURL = column.link_item.url;
                        let linkTitle = column.link_item.title;
                            return(
                                <div className="link-col" key={`text-section-link-${i}`}>
                                    <Link to={linkURL}>{linkTitle}</Link>
                                </div>
                            )
                        })
                    }
                </div>
                }
            </div>
        </section>
    )
}

export default BasicText;