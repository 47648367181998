import React from 'react';

const Banner = (props) => {
    let bannerUrl = props.bannerURL;
    bannerUrl = bannerUrl.split('/').pop();
    bannerUrl = `/`+bannerUrl;

    return(
    <section className="banner-section" >
        <img src={`${bannerUrl}`} alt={props.altText} />
    </section>
    );
}

export default Banner;