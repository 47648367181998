import React from 'react';
import {Link} from 'gatsby';
//import BlueChevron from '../assets/new-blue-chevron.svg';

class LinkListBlocks extends React.Component{
      
      componentDidMount() {
        const linklistItems = document.querySelectorAll('.link-wrap a');
        linklistItems.forEach(function (linklistItem) { 
            //Get last two
            var splitText1 = linklistItem.textContent.split(" ").splice(-1).join(" ");
            //Get first two
            var splitText2 = linklistItem.textContent.split(" ").slice(0, -1).join(" ");
            //Replace HTML
            linklistItem.innerHTML = splitText2 + ' <span class="last-word">' + splitText1 + '</span>';
        });
        
      }

      render(){
        return(

            <div className="linklist-columns">
            <div className="right-col-content-wrap">
                <div className="section-columns">
        {this.props.blocks.map((column, i) => {
                let colImg = column.column_image.split('/').pop();
                let colImgAlt = column.column_image_alt_text
                let linkList = column.column_link_list;
                let colTitle = column.column_title;

                    return(
                        <div className="linklist-column" key={`linklist-col-${i}`}>
                            <div className="top-row">
                            {colTitle &&
                            <h2 className="column-heading" dangerouslySetInnerHTML={{__html: colTitle}} />
                            }
                            {colImg &&
                            <img src={`/${colImg}`} alt={colImgAlt} />
                            }
                            </div>
                            {linkList &&
                            <div className="linklist-wrapper">
                            <h3 className="listing-label">Choose from the list below:</h3>
                                {linkList.map((linkItem, i) => { 
                                    return(
                                        <div className="link-wrap" key={`link-item-${i}`}>
                                        <Link 
                                        to={linkItem.link_list_link.url }
                                        >{linkItem.link_list_link.title }
                                        </Link>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                            }
                        
                        </div>
                    )
                })
        }
        </div>
        </div>
        </div>
        )
      }
}


export default LinkListBlocks;