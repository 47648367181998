import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import AlertIcon from '../assets/alert-icon.svg';

const AlertRow = () => {
 return(
    <StaticQuery query={graphql`
    {
        allGlobalsJson {
          edges {
            node {
              alert_description_text
            }
          }
        }
      }
    `} render={props =>(
        <div className="alert-row">
            <AlertIcon className="icon" />
            <div className="text-wrap" dangerouslySetInnerHTML={{ __html: props.allGlobalsJson.edges[0].node.alert_description_text }} />
        </div>
    )} />
 )
}

export default AlertRow;