import React from 'react';
import {Link} from 'gatsby';
import AlertRow from './AlertRow';

const TopBlueSection = (props) => {

    return(
        <section className={`top-blue-section component-top-blue-section${(props.rcStyle === 'all-grey') ? ' not-blue' : ''}`}>
            <div className={props.wrapperClass}>
                {props.bc &&
                    <div className="breadcrumbs-wrap">
                       { props.bc.map((column, i) => {
                            let bcLink = column.breadcrumb_link;
                            return(
                                <div className="bc-item" key={`bc-item-${i}`}>
                                    <Link to={bcLink.url} dangerouslySetInnerHTML={{__html: bcLink.title}} />
                                    <span className="divider">&gt;</span>
                                </div>
                            )
                        })}
                        <div className="bc-item" dangerouslySetInnerHTML={{__html: props.pageTitle}}  />
                    </div>
                }
                <div className="section-main-content" dangerouslySetInnerHTML={{ __html: props.sectionText }} />
                {props.iconTxtRows &&
                <div className="icon-text-rows">
                    {
                        props.iconTxtRows.map((row , i) => {
                            let iconPath = row.icon_and_text_row_icon.split('/').pop();
                        return(
                            <div className="icon-text-row" key={`icon-text-row-${i}`}>
                                <div class="icon-wrap">
                                    <img src={`/${iconPath }`} alt={row.icon_and_text_row_alt_text} />
                                </div>
                                <div className="text-wrap" dangerouslySetInnerHTML={{ __html: row.icon_and_text_row_text }} />
                            </div>
                        )})
                    }
                </div>
                }
                {props.imgTxtCols &&
                <div className="img-text-cols">
                    {props.imgTxtCols.map((column, i) => {
                        let colImg = column.column_image.split('/').pop();
                        let imgAlt = column.image_alt_text
                         return(
                             <div className="img-text-col" key={`img-col-${i}`}>
                                    <img src={`/${colImg}`} alt={imgAlt} />
                                <p className="column-text">{column.column_text}</p>
                             </div>
                         )
                      })
                    }
                </div>
                }

                {props.hcTxtAndLnks &&
                <div className="hc-text-and-links-section">
                    {props.hcTxtAndLnks.map(column => (
                        <div className="hc-text-link-col">
                            {column.half_column_text &&
                                <div className="column-text" dangerouslySetInnerHTML={{ __html: column.half_column_text }} />
                            }
                            {column.half_column_links &&
                                <div className="links-wrap">
                                    {column.half_column_links.map(linkItem => (
                                        <div className="link-wrap">
                                        <a 
                                            href={linkItem.half_txt_link_column_link.url}
                                            className="scrollto-link"
                                        >
                                        {linkItem.half_txt_link_column_link.title}
                                        </a>
                                        </div>
                                    ))
                                    }
                                </div>
                            }
                            
                        </div>
                    ))}
                </div>
                }
                
                {props.anchorLinkCols &&
                <div className="anchor-cols">
                    {props.anchorLinkCols.map((column, i) => {
                        let linkURL = column.column_link.url;
                        let linkTitle = column.column_link.title;
                        let linkTarget = '';
                        let arrowDir = column.link_arrow_direction;

                        if(linkURL.includes('.pdf')){
                            linkTarget = "_blank";
                            linkURL = column.column_link.url.split('/').pop();
                            linkURL = '/'+linkURL;
                        } 
                            return(
                                <div className={`link-col`} key={`anchor-link-${i}`}>
                                    <a href={linkURL} 
                                        target={linkTarget}
                                        className={arrowDir}
                                        >
                                    {linkTitle}
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
                }

                {(props.alertRow === 'display') &&
                    <AlertRow />
                }

            </div>
        </section>
    )
}

export default TopBlueSection;