import React from 'react';
import {graphql, StaticQuery, Link} from 'gatsby';
import Logo from '../assets/logo.svg';

class SideMenuNutro extends React.Component{
  render() {

    return(
      
      <StaticQuery query={graphql`
      {
        allNutroSideMenuJson{
          edges {
            node {
              url
              title
              ID
              classes
             
            }
          }
        }
      }
    `} render={props =>(
        <div className={`side-menu ${this.props.extraClass}`}>
       
        {this.props.withLogo &&
          <Link to="/" aria-label="link to homepage" >
            <Logo className="logo" />
          </Link>
        }
          <nav className="menu-wrap">
            <ul className="primary">
              {props.allNutroSideMenuJson.edges.map(item => (
                <li  key={item.node.ID} >
                <Link 
                  to={item.node.url} 
                  className={`side-menu-link ${item.node.classes}`} 
                  activeClassName={`${item.node.url !== '/' ? 'active stay-active' : null}`}   
                  partiallyActive={false}       
                  >
                      {item.node.title}
                  </Link> 
                  {item.node.sub  &&
                    <ul className="secondary">
                    {item.node.sub.map(item2 => (
                      <li key={item2.ID} >
                      <Link 
                      to={item2.url} 
                      className="side-menu-link"
                      activeClassName="active"    
                      partiallyActive={true}           
                      >
                          {item2.title}
                      </Link> 
                      {item2.sub  &&
                        <ul className="tertiary">
                        {item2.sub.map(item3 => (
                          <li key={item3.ID} >
                          <Link 
                          to={item3.url} 
                          className="side-menu-link"
                          activeClassName="active"    
                          partiallyActive={true}           
                          >
                              {item3.title}
                          </Link> 
                          {item3.sub  &&
                            <ul className="quarternary">
                            {item3.sub.map(item4 => (
                              <li key={item4.ID} >
                              <Link 
                              to={item4.url} 
                              className="side-menu-link"
                              activeClassName="active"  
                              partiallyActive={true}                
                              >
                                  {item4.title}
                              </Link> 
                              </li>
                            ))}
                            </ul>
                          }  
                          </li>
                        ))}
                        </ul>
                      }
                      </li>
                    ))}
                    </ul>
                  }
                </li>
              ))}
            </ul>
          </nav>
        </div>
    )} />
    )
  }
}

export default SideMenuNutro;
