import React from 'react';
import LayoutWithSide from '../components/layout-with-menu';
import { Helmet } from "react-helmet";
import Banner from '../components/Banner';
import TopBlueSection from '../components/TopBlueSection';
import BasicText from '../components/BasicText';
import SingleImage from '../components/SingleImage';
import LinkListBlocks from '../components/LinkListBlocks';
import NavigationalButtonSet from '../components/NavigationalButtonSet';
import ChecklistGroup from '../components/ChecklistGroup';
import KitCompletionStepGraphic from '../components/KitCompletionStepGraphic';
import VideoModalModule from '../components/VideoModalModule';

export default ({pageContext, ...props}) => (
    <LayoutWithSide rcStyle={pageContext.right_column_styling} slug={pageContext.slug} parent={pageContext.parent_page} >
        <Helmet
            htmlAttributes={{
            lang: 'en',
            }}
        >
        {pageContext.noindex_page === 'noindex' &&
        <meta name="robots" content="noindex, follow"></meta>
        }
          <meta charSet="utf-8" />
          <title>MyRetirement | {pageContext.title}</title>
          <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="c71e0e36-b367-4b3d-a1ba-3f53aed3fc7b-test" ></script>
        <script type="text/javascript">
        function OptanonWrapper() { }
        </script>
        </Helmet>
    
    {pageContext.modular_content &&
        <div className="page-content">
            {pageContext.modular_content.map((section, i) => {
                const layoutName = section.site_module;
                switch (layoutName) {
                  case "top_blue_section":
                        return(
                            <TopBlueSection 
                                key={`mc-${i}`}
                                rcStyle={pageContext.right_column_styling}
                                wrapperClass="right-col-content-wrap"
                                bc={section.breadcrumb_links} 
                                pageTitle={pageContext.title}
                                sectionText={section.section_copy}
                                iconTxtRows={section.icon_and_text_rows}
                                imgTxtCols={section.image_and_text_columns}
                                hcTxtAndLnks={section.half_columns_text_and_links}
                                anchorLinkCols={section.anchor_link_columns}
                                alertRow={section.alert_row_display}
                            />
                        )
                  case "banner_image_section":
                        return(
                            <Banner 
                                key={`mc-${i}`}
                                bannerURL={section.banner_image} 
                                altText={section.image_alt_text}
                            />
                        )
                  case "basic_text":
                      return(
                          <BasicText 
                            key={`mc-${i}`}
                            wrapperClass="right-col-content-wrap"
                            rcStyle={pageContext.right_column_styling}
                            sectionText={section.basic_text_section_text}
                            sectionID={section.text_section_id}
                            icon={section.basic_text_optional_icon}
                            iconalt={section.basic_text_optional_icon_alt_text}
                            linksArray={section.basic_text_optional_links}
                            tcColumns={section.tc_image_and_text_column}
                            displayLinks={section.display_links}
                            alert={section.text_section_display_alert_icon}
                          />
                      )
                  case "single_image_section":
                      return(
                          <SingleImage 
                          key={`mc-${i}`}
                          imgUrl={section.single_image_section_image}
                          imgAlt={section.single_image_section_alt_text}
                          imgPadding={section.single_image_left_right_padding}
                          optionalHeading={section.single_image_optional_small_heading}
                          />
                      )
                case "half_column_info_link_blocks":
                    return(
                        <LinkListBlocks 
                            key={`mc-${i}`}
                            blocks={section.link_list_columns}
                        />
                    )
                case "video_modal":
                    return(
                        <VideoModalModule 
                            key={`mc-${i}`}
                            videoTitle = {section.video_modal_optional_title}
                            optionalLinks = {section.video_section_optional_links}
                            videoThumb={section.video_section_video_thumbnail} 
                            videoCode={section.video_section_embedded_video}
                            alert={section.video_modal_display_alert_icon}
                            videoTitleStyle={section.video_modal_title_style}
                        />
                    )
                case "navigational_button_sets":
                    return(
                        <NavigationalButtonSet 
                            key={`mc-${i}`}
                            leftBtn={section.left_button_nbs}
                            rightBtn={section.right_button_nbs}
                        />
                    )
                case "checklist_group":
                    return(
                        <ChecklistGroup
                            key={`mc-${i}`}
                            groupContent={section.checklist_group_content}
                        />
                    )
                case "kit_completion_step":
                    return(
                        <KitCompletionStepGraphic 
                            key={`mc-${i}`}
                            alert={section.kit_completion_with_alert}
                            sectionText={section.kit_completion_section_text}
                            sectionImg={section.kit_completion_step_graphic}
                            imgAltText={section.kit_completion_step_graphic_alt_text}
                            sectionImgSize={section.kit_completion_step_graphic_size}
                        />
                    )
                default:
                    // Do nothing
                }  return null;
              })
              
            }
        </div>
    }
    </LayoutWithSide>
);